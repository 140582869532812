import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalEnum, PropertyHomeTypeEnum } from '@enums';
import { generateAddressUtils, replaceSpecialCharacters } from '@helpers';
import { IPropertyListModel } from '@interfaces';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { injectDestroyService, ModalsService, provideDestroyService, SavedHomesService } from '@service';
import * as SavedHomesActions from '@store';
import { selectHeaderLogged } from '@store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-property-default-card',
  templateUrl: './property-default-card.component.html',
  styleUrls: ['./property-default-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideDestroyService()],
  standalone: false,
})
export class PropertyDefaultCardComponent implements OnInit, AfterViewInit {
  // Temporary search page uses fake photos.
  @Input() property: IPropertyListModel;
  @Input() mode: 'search-page' | 'your-homes' = 'search-page';
  @Input() buttonFunction: 'favorite' | 'activation' | 'menu' = 'favorite';

  @Output() showOnMapAction = new EventEmitter();
  @Output() clearShowOnMapAction = new EventEmitter();
  @Output() propertyActivation = new EventEmitter<any>();
  @Output() editHomeDetails = new EventEmitter<any>();

  private readonly destroy$ = injectDestroyService();
  private isLoggedUser: boolean = false;

  public optionsBlockTooltip = '';
  public isCorrectLocation = false;

  constructor(
    private savedHomesService: SavedHomesService,
    private router: Router,
    private modalsService: ModalsService,
    private store: Store,
    private cdf: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectHeaderLogged), takeUntil(this.destroy$)).subscribe((res) => (this.isLoggedUser = res));
  }

  ngAfterViewInit(): void {
    this.optionsBlockTooltip = this.property.cardInfoLine?.length
      ? this.property.cardInfoLine.map((item) => `${item.count ? item.count + ' ' : ''}${item.name}`).join(' | ')
      : '';

    if (this.property.homeType) {
      const key = Object.keys(PropertyHomeTypeEnum).find(
        (k) => PropertyHomeTypeEnum[k] === this.property.homeType.toString(),
      );

      this.property = {
        ...this.property,
        cardHomeType: `enums.property_home_types.${key}`,
      };
    }

    this.isCorrectLocation = this.property.location.lat !== 0 && this.property.location.long !== 0;
  }

  public showOnMap(property: IPropertyListModel) {
    this.showOnMapAction.emit(property);
  }

  public clearShowOnMap() {
    this.clearShowOnMapAction.emit();
  }

  public goToDetailsProperty(event, property, ignoreClickRules = false) {
    if (this.mode === 'search-page') {
      if (!ignoreClickRules) {
        if (event.target.tagName !== 'IMG' && event.target.tagName !== 'P' && event.target.className !== 'details') {
          return;
        }
      }

      let addressFormatted;

      if (property.addressListing) {
        addressFormatted = property.addressListing.replace(/[^\w]/gi, '-').replace(/-{2,}/g, '-').toLowerCase();
      }

      if (property.fullAddress) {
        addressFormatted = generateAddressUtils(replaceSpecialCharacters(property.fullAddress))
          .replace(/[^\w]/gi, '-')
          .toLowerCase();

        addressFormatted = addressFormatted.replace(/-{2,}/g, '-');
      }

      const rooms = property.rooms ? `${property.rooms}-rooms-` : '';
      const bedroom = property.bedroom ? `${property.bedroom}-bedroom-` : '';
      const path = `/property/${property.id}/${rooms}${bedroom}${addressFormatted}`;

      this.router.navigate([path]);
      return;
    }

    if (this.mode === 'your-homes') {
      this.editHomeDetails.emit(property);
      return;
    }
  }

  public addToSaved(property: IPropertyListModel) {
    if (!this.isLoggedUser) {
      this.modalsService.open(ModalEnum.AUTH);
      return;
    }

    this.savedHomesService
      .add(property.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.property = { ...this.property, isSavedHome: true };
        this.store.dispatch(SavedHomesActions.addSavedHomes({ data: { ...property, isSavedHome: true } }));
        this.cdf.detectChanges();
      });
  }

  public deleteSaved(property: IPropertyListModel, e?: any) {
    switch (this.mode) {
      case 'search-page':
        this.savedHomesService
          .delete(property.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((_) => {
            this.property = { ...this.property, isSavedHome: false };
            this.store.dispatch(SavedHomesActions.removeSavedHomes({ data: property }));
            this.cdf.detectChanges();
          });
        break;
      case 'your-homes':
        e.stopPropagation();
        e.preventDefault();

        if (!this.isCorrectLocation) {
          return;
        }

        this.propertyActivation.emit(!property.isActiveStatus);
        this.cdf.detectChanges();
        break;
    }
  }

  public getTooltipText(): string {
    return this.property.isActiveStatus
      ? this.translateService.instant('own_properties_details.deactivate')
      : this.isCorrectLocation
        ? this.translateService.instant('own_properties_details.activate')
        : this.translateService.instant('own_properties_details.error_address');
  }

  protected readonly generateAddressUtils = generateAddressUtils;
}
