<section class="search-module">
  <img
    class="lazyload"
    loading="lazy"
    src="assets/image/bg/home-search-bg.jpg"
    data-src="assets/image/bg/home-search-bg.jpg"
    alt="Search properties"
  >
  <div class="overlay"></div>
  <header>
    <p>{{'home_searchinput.title' | translate}}</p>
  </header>

  <article class="search-form" [formGroup]="form" (clickOutside)="closeSearch()">
    <div class="search-block">
      <label>
        <input
          type="text"
          autofocus
          placeholder="{{'home_searchinput.search_placeholder' | translate}}"
          formControlName="input"
          (keydown.arrowUp)="selectPreviousOption()"
          (keydown.arrowDown)="selectNextOption()"
          (keydown.enter)="selectCurrentOption()"
        />
      </label>
      <button>{{'home_searchinput.search' | translate}}</button>
    </div>
    <div class="results">
      <ng-container *ngIf="statesFull.length; else searchNotFound">
        <p *ngFor="let item of statesFull; let index = index;"
           [class.selected]="index === currentOptionIndex"
           (click)="selectAddress(item)">
          <span [searchHighlight]="form.controls.input.value">{{item.view}}</span>
        </p>
      </ng-container>
    </div>
  </article>
</section>

<ng-template #searchNotFound>
  <p *ngIf="form.controls.input.value.length">
    {{"home_searchinput.no_search_result" | translate: {'%query': form.controls.input.value.trim()} }}
  </p>
</ng-template>
