import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ description }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn apply" (click)="closeModal.emit(true)">{{ applyButtonText }}</button>
      <button type="button" class="btn cancel" (click)="closeModal.emit(false)">{{ cancelButtonText }}</button>
    </div>
  `,
  styleUrls: ['./modal-confirm.component.scss'],
  standalone: false,
})
export class ModalConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() applyButtonText: string;
  @Input() cancelButtonText: string;

  @Output() closeModal = new EventEmitter<boolean>();

  ngOnInit() {}
}
