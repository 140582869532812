import { Component, EventEmitter, Output } from '@angular/core';
import { LiveSearch } from '@helpers';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NominatimService } from '@service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-address-search-input',
  templateUrl: './address-search-input.component.html',
  styleUrls: ['./address-search-input.component.scss'],
  standalone: false,
})
export class AddressSearchInputComponent extends LiveSearch {
  @Output() addressSelected = new EventEmitter<any>();

  constructor(
    public translate: TranslateService,
    public router: Router,
    public formBuilder: FormBuilder,
    public nominatimService: NominatimService,
    public store: Store,
  ) {
    super(router, formBuilder, nominatimService, store);
  }

  override performSearch() {
    // empty override to prevent search actions
  }

  override selectAddressAction(item: any): void {
    this.addressSelected.emit(item);
  }
}
