import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Map } from 'leaflet';
import { injectDestroyService, MapSettingService, OpenStreetMapService, provideDestroyService } from '@service';
import { combineLatest, Subject } from 'rxjs';
import { selectMapCenter, selectMapZoom } from '@store';
import { select, Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/local-storage.service';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageEnum } from '@enums';
import { SimpleMapService } from '../../services/simple-map.service';

@Component({
  selector: 'app-simple-map',
  template: `
    <div [id]="mapContainerId" class="simple-map-container">
      <div class="spinner-large" [hidden]="loading"></div>
    </div>
  `,
  styleUrls: ['./simple-map.component.scss'],
  providers: [provideDestroyService()],
  standalone: false,
})
export class SimpleMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public disabled: boolean = false;
  @Input() public actionForReload: number;
  @Input() public zoom: number;

  @Input() public navigateToCoordinates: { lat: number; lon: number } | null;
  @Output() public selectedAddressChanged = new EventEmitter<any>();

  public map: Map;
  public loading = false;
  public mapContainerId = '';

  private readonly destroy$ = injectDestroyService();
  private readonly responseGetDistrict$: Subject<void>;

  eventsAreCreated = false;

  constructor(
    public mapService: SimpleMapService,
    public mapSetting: MapSettingService,
    private osm: OpenStreetMapService,
    private store: Store,
    private ls: LocalStorageService,
  ) {
    this.responseGetDistrict$ = new Subject();
    this.mapContainerId = 'simple_map_' + this.generateRandomNumber(10);
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.mapService.L) {
        this.initWithParams();
        this.changeLoadingSpinner();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navigateToCoordinates) {
      if (changes.navigateToCoordinates.currentValue) {
        const mockLoc = this.ls.getItem(LocalStorageEnum.GeoUserInfo).loc;
        let userLoc = changes.navigateToCoordinates.currentValue;

        if (!userLoc.lat && !userLoc.lon) {
          userLoc = { lat: +mockLoc.split(',')[0], lon: +mockLoc.split(',')[1] };
        }

        setTimeout((_) => {
          this.mapService.flyTo(userLoc.lat, +userLoc.lon, this.mapSetting.defaultZoomMap);
          this.mapService.removeMarker(this.mapService.marker);
          this.mapService.marker = this.mapService.addMarker(userLoc.lat, userLoc.lon);
          this.mapService.setZoom(this.zoom);
        }, 100);
      } else {
        this.mapService.removeMarker(this.mapService.marker);
      }
    }
  }

  generateRandomNumber(length: number): string {
    const characters = '0123456789';
    return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
  }

  createMapEvents() {
    this.map.on('click', (e) => {
      if (this.disabled) {
        return;
      }

      this.getDistrictFigure(e.latlng.lat, e.latlng.lng);
      this.mapService.removeMarker(this.mapService.marker);
      this.mapService.marker = this.mapService.addMarker(e.latlng.lat, e.latlng.lng);
      this.navigateToCoordinates = { lat: e.latlng.lat, lon: e.latlng.lng };
    });
  }

  getDistrictFigure(lat, lon) {
    this.responseGetDistrict$.next();
    this.osm
      .getDistrict(lat, lon)
      .pipe(takeUntil(this.responseGetDistrict$))
      .subscribe((district: any) => {
        this.selectedAddressChanged.emit(district);
      });
  }

  public initWithParams(): void {
    combineLatest([this.store.pipe(select(selectMapCenter)), this.store.pipe(select(selectMapZoom))])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([mapCenter, zoom]) => {
        const [userLat, userLon] = this.ls.getItem(LocalStorageEnum.GeoUserInfo).loc.split(',');
        const lat = mapCenter ? `${mapCenter.lat}` : userLat;
        const lon = mapCenter ? `${mapCenter.long}` : userLon;
        this.initMap(lat, lon, zoom);
      });
  }

  changeLoadingSpinner() {
    this.loading = !this.loading;
  }

  initMap(lat: string, lon: string, zoom = this.mapSetting.defaultZoomMap): void {
    if (!this.map) {
      this.map = this.mapService.setupMap({ lat, lon, zoom }, this.mapContainerId);
      if (!this.eventsAreCreated) {
        setTimeout(() => this.createMapEvents());
        this.eventsAreCreated = true;
      }
    } else {
      this.mapService.flyTo(+lat, +lon, zoom);
    }
  }

  // To be called from parent component
  public removeMarker(): void {
    if (this.mapService.marker) {
      this.mapService.removeMarker(this.mapService.marker);
    }
  }

  ngOnDestroy(): void {
    this.map?.off('click');
    this.responseGetDistrict$.next();
    this.mapService.destroyMap();
  }
}
