<div class="variables">
  <ng-container *ngIf="multiSelect && hasSelectAll">
    <div class="variable">
      <span class="mark-item mark-checkbox mark-checked radius"></span>
      <span class="selection-label" (click)="selectAll(!allSelected)">
        {{ (allSelected ? "custom_radio.deselect_all" : "custom_radio.select_all") | translate }}
      </span>
    </div>
  </ng-container>
  <div
    *ngFor="let item of radioList; let i = index"
    class="variable"
    [class.active]="multiSelect ? radio?.includes(item.value) : item.value === radio"
    [class.type-any]="item.value ==='0'"
    id="{{ item.value }}"
  >
    <ng-container *ngIf="useMark">
      <span
        class="mark-item"
        [class.mark-radio]="!multiSelect"
        [class.mark-checkbox]="multiSelect"
        [class.mark-checked]="multiSelect ? radio?.includes(item.value) : item.value === radio"
      ></span>
    </ng-container>
    <label class="check-label" *ngIf="item.value !== '0'"
           [class.plus]="prefix?.length && i !==0">{{ item.name | translate }}</label>
  </div>
  <button class="apply" *ngIf="showApplyBtn" (click)="closeAction()">{{ 'filter_bedrooms.apply' | translate }}</button>
</div>
