import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getImageUrlDemo, getImageUrlThumb } from '@helpers';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SliderComponent {
  public loadingImage = true;

  // Temporary search page uses fake photos.
  @Input() photos = [];
  @Input() hidePhoto = true;
  @Input() isDemoPhoto = false;
  @Input() size: 'default-size' | 'medium-size' | 'for-map' = 'default-size';

  public prevSlidePhoto() {
    this.loadStatus(true);
    const tempArr = [...this.photos];
    this.photos = tempArr.concat(tempArr.splice(0, 1));
  }

  public nextSlidePhoto() {
    this.loadStatus(true);
    const tempArr = [...this.photos];
    this.photos = tempArr.concat(tempArr.splice(0, tempArr.length - 1));
  }

  public checkUrlPhoto(photos: string[]) {
    if (photos && this.isDemoPhoto) {
      return getImageUrlDemo(photos[0]);
    }

    if (photos && photos[0] !== undefined) {
      return getImageUrlThumb(photos[0]);
    }

    return './assets/image/default-img.jpg';
  }

  public loadStatus(status: boolean): void {
    this.loadingImage = status;
  }
}
