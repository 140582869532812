import { Injectable } from '@angular/core';
import { environment } from '@env';
import { MapSettingService } from './map-setting.service';
import { SavedSearchesService } from './saved-searches.service';
import { BaseMapService } from './base-map-service';

@Injectable({
  providedIn: 'root',
})
export class SimpleMapService extends BaseMapService {
  constructor(
    private mapSetting: MapSettingService,
    private savedSearchesService: SavedSearchesService,
  ) {
    super();
  }

  setupMap(data: { zoom?: number; lat?: string; lon?: string }, containerId: string) {
    const localMap = this.savedSearchesService.loadLastMapSettingToLocalStorage();
    let lat = data?.lat ?? this.mapSetting.defaultParametersMap.lat;
    let lon = data?.lon ?? this.mapSetting.defaultParametersMap.lon;
    let zoom = data?.zoom ?? this.mapSetting.defaultZoomMap;

    if (localMap && localMap.centerMap && localMap.currentZoom) {
      lat = localMap.centerMap.lat;
      lon = localMap.centerMap.lng;
      zoom = localMap.currentZoom;
    }

    this.map = this.L.map(containerId, {
      minZoom: this.mapSetting.defaultMinZoomMap,
      maxZoom: this.mapSetting.defaultMaxZoomMap,
    }).setView([+lat, +lon], zoom);

    this.removeDefaultIcon();
    this.maxBounds = this.map.getBounds();
    this.maxBounds = this.maxBounds.pad(this.padValue).contains({ lat, lng: lon });

    this.tileLayer = this.L.tileLayer(environment.TILES_MAP, {
      maxZoom: this.mapSetting.defaultMaxZoomMap,
    });
    this.map.addLayer(this.tileLayer);
    return this.map;
  }

  removeDefaultIcon() {
    // @ts-ignore
    delete this.L.Icon.Default.prototype._getIconUrl;
    this.L.Icon.Default.mergeOptions({
      iconUrl: this.defaultIcon,
      shadowUrl: null,
      iconRetinaUrl: this.defaultIcon,
    });

    this.L.Icon.Default.mergeOptions({
      iconSize: [0, 0],
      shadowSize: [0, 0],
    });
  }

  destroyMap(): void {
    this.map.remove();
  }

  setZoom(zoom: number): void {
    this.map.setZoom(zoom);
  }
}
