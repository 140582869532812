<div
  class="sort-header text-ellipsis"
  *ngIf="sortOrder"
  (clickOutside)="handleOpenState(false)"
  (click)="handleOpenState()"
>
  <p>
    {{'property_sort.sorted_by' | translate}}: <label class="selected-sort-order">{{sortOrder.name | translate}}</label>
  </p>
</div>
<div class="sort-options-list" *ngIf="showOptions">
  <p
    *ngFor="let option of optionsList"
    [class.selected]="option.value === sortOrder.value"
    (click)="sortChanged(option)"
  >
    {{option.name | translate}}
  </p>
</div>
