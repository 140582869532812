<div class="dropdown" [class.mobile]="mode === 'mobile'">
  <div class="drop-button">
    <span class="selected-lang">{{ selectedLanguage.value }}</span>
  </div>
  <svg *ngIf="mode === 'mobile'" width="20" height="10" viewBox="0 0 14 8" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1643 0.755019C12.9907 0.581376 12.7085 0.581376 12.5349 0.755019L7.00001 6.30075L1.45427 0.755019C1.28063 0.581376 0.998456 0.581376 0.824813 0.755019C0.651169 0.928662 0.651169 1.21083 0.824813 1.38448L6.67443 7.23409C6.76125 7.32091 6.86977 7.36432 6.98915 7.36432C7.09768 7.36432 7.21706 7.32091 7.30388 7.23409L13.1535 1.38448C13.338 1.21083 13.338 0.928662 13.1643 0.755019Z" />
  </svg>

  <div class="dropdown-content">
    <a
      *ngFor="let lng of lngList"
      [class.selected]="lng.selected"
      (click)="selectLanguage($event, lng)"
      href="#"
      rel="nofollow"
    >
      <span style="text-transform: uppercase">{{ lng.value }}</span>
    </a>
  </div>
</div>
