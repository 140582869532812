import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { unselected } from '../../constants/unselected';

/**
 * RangeSelectComponent is a custom Angular component that allows users to select a range of values
 * using a slider. The selected range is bound to a reactive form and can be formatted with a prefix.
 * The component also provides an option to display an "Apply" button and a shadow effect.
 */
@Component({
  selector: 'app-range-select',
  templateUrl: './range-select.component.html',
  styleUrls: ['./range-select.component.scss'],
  standalone: false,
})
export class RangeSelectComponent implements OnInit {
  /**
   * Determines if the component should be added to the body element.
   * @type {boolean}
   */
  @Input() addToBody: boolean = false;

  /**
   * Text to display in the header of the component.
   * @type {string}
   */
  @Input() headerText: string = '';

  /**
   * The FormGroup instance to which the range values are bound.
   * @type {FormGroup}
   */
  @Input() form: FormGroup;

  /**
   * Additional CSS classes to apply to the component.
   * @type {string}
   */
  @Input() classes: string = '';

  /**
   * The name of the FormControl for the "from" value in the range.
   * @type {string}
   */
  @Input() fromCtrlName: string = '';

  /**
   * The name of the FormControl for the "to" value in the range.
   * @type {string}
   */
  @Input() toCtrlName: string = '';

  /**
   * A prefix to display before the range values.
   * @type {string}
   */
  @Input() prefix: string = '';

  /**
   * Determines if the "Apply" button should be shown.
   * @type {boolean}
   */
  @Input() showApplyBtn: boolean = false;

  /**
   * Determines if a shadow effect should be applied to the component.
   * @type {boolean}
   */
  @Input() showShadow: boolean = false;

  /**
   * Determines if the range values should be formatted as numbers.
   * @type {boolean}
   */
  @Input() useNumberFormatting: boolean = true;

  /**
   * The range of numbers that can be selected.
   * @type {number[]}
   */
  @Input() range: number[] = [];

  /**
   * A dictionary of numbers used to populate the slider values.
   * @type {number[]}
   */
  @Input() dictionary: number[] = [];

  /**
   * EventEmitter that emits an event when the component is closed.
   * @type {EventEmitter<void>}
   */
  @Output() closeEvent = new EventEmitter<void>();

  /**
   * The current values selected in the slider.
   * @type {number[]}
   */
  public values: number[] = [];

  /**
   * A constant value representing an unselected state.
   * @type {typeof unselected}
   */
  public unselected = unselected;

  /**
   * Initializes the component, setting the initial values from the dictionary.
   * @returns {void}
   */
  ngOnInit(): void {
    this.values = this.dictionary;
  }

  /**
   * Retrieves the value from the values array based on the given index.
   * @param {number} index - The index of the value to retrieve.
   * @returns {number} - The value at the specified index.
   */
  public getValue(index: number): number {
    return this.values[index];
  }

  /**
   * Handles changes to the slider and updates the FormGroup controls with the selected range values.
   * @param {any} event - The slider change event containing the selected values.
   * @returns {void}
   */
  public onSliderChange(event: any): void {
    this.form.get(this.fromCtrlName).setValue(this.getValue(event.values[0]).toString());

    if (this.getValue(event.values[1]) === this.getValue(this.values.length - 1)) {
      this.form.get(this.toCtrlName).setValue('0');
      return;
    }

    this.form.get(this.toCtrlName).setValue(this.getValue(event.values[1]).toString());
  }

  /**
   * Emits the close event to signal that the component should be closed.
   * @returns {void}
   */
  public closeAction(): void {
    this.closeEvent.emit();
  }
}
