@use "variables" as *;

app-checkbox {
  input[type="checkbox"] {
    visibility: hidden;
    width: 0;
    height: 0;
    display: none;
  }

  &.ng-touched {
    &.ng-invalid {
      input {
        &:not(:checked) {
          & + .custom-checkbox {
            border: 1px solid $brand-green;
            background: $brand-white;
          }
        }
      }
    }

    input {
      &:not(:checked) {
        & + .custom-checkbox {
          background: $brand-white;
          border: 1px solid $gray-04;
        }
      }
    }
  }
}

.custom-checkbox, .custom-checkbox-disabled {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid $gray-04;
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    background: $gray-05;
  }
}

.custom-checkbox-disabled {
  background: $gray-06;
  cursor: not-allowed;
}

input {
  &:checked {
    & + .custom-checkbox {
      background-image: url("../../../../assets/image/checkbox-gray.svg");
      background-size: 13px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

app-checkbox.ng-touched.ng-valid .custom-checkbox,
app-checkbox.ng-untouched.ng-valid input:checked + .custom-checkbox {
  border: 1px solid $brand-green;
  background-color: $brand-green;
  background-image: url("../../../../assets/image/checkbox-white.svg");
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: center;
}
