import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyShortString',
  standalone: false,
})
export class MoneyShortStringPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000000) {
      const formattedValue = Math.floor(value / 100000) / 10;
      return `${formattedValue}M`;
    }
    if (value >= 1000) {
      const formattedValue = Math.floor(value / 100) / 10;
      return `${formattedValue}K`;
    }
    return value.toString();
  }
}
