import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LiveSearch } from 'app/shared/helpers';
import { NominatimService } from '@service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  standalone: false,
})
export class SearchInputFormComponent extends LiveSearch implements OnInit {
  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public nominatimService: NominatimService,
    public store: Store,
    private elementRef: ElementRef,
  ) {
    super(router, formBuilder, nominatimService, store);
  }

  ngOnInit() {
    const lazyImages = this.elementRef.nativeElement.querySelectorAll('img.lazyload');
    lazyImages.forEach((image: any) => {
      const highQualitySrc = image.getAttribute('data-src');
      const newImage = new Image();
      newImage.src = highQualitySrc;
      newImage.onload = () => {
        image.src = highQualitySrc;
        image.classList.add('loaded');
      };
    });
  }
}
