import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToShortString',
  standalone: false,
})
export class NumberToShortStringPipe implements PipeTransform {
  transform(value: number | string): string {
    const numValue = typeof value === 'string' ? parseInt(value, 10) : value;
    if (numValue >= 0 && numValue < 1000) {
      return numValue.toString();
    } else if (numValue >= 1000 && numValue <= 9999) {
      const decimal = numValue % 1000;
      const thousands = Math.floor(numValue / 1000);
      const formattedDecimal = decimal > 0 ? '.' + Math.floor(decimal / 100) : '';
      return `${thousands}${formattedDecimal}K`;
    } else if (numValue >= 10000) {
      return `${Math.floor(numValue / 1000)}K`;
    }
    return '';
  }
}
