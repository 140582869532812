import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  standalone: false,
})
export class PagingComponent implements OnChanges {
  @Input() pageSize: number;
  @Input() totalElements: number;
  @Input() currentPage: number = 1;
  @Output() pageChanged = new EventEmitter<number>();

  public visiblePages: number[];
  public totalPages: number = 1;
  public showEllipsisBeforeFirstPage: boolean;
  public showEllipsisAfterLastPage: boolean;

  constructor(private elementRef: ElementRef) {
    this.visiblePages = [];
    this.showEllipsisBeforeFirstPage = false;
    this.showEllipsisAfterLastPage = false;
  }

  public ngOnChanges(): void {
    this.updateVisiblePages();
  }

  public changePage(page: number): void {
    if (page < 1 || page > this.totalPages || page === this.currentPage) {
      return;
    }

    this.currentPage = page;
    this.updateVisiblePages();
    this.pageChanged.emit(page);
  }

  public updateVisiblePages(): void {
    const maxVisiblePages = Math.floor(this.elementRef.nativeElement.offsetWidth / 50);
    this.totalPages = Math.ceil(this.totalElements / this.pageSize);

    let firstVisiblePage = Math.max(this.currentPage - Math.floor(maxVisiblePages / 2), 1);
    let lastVisiblePage = Math.min(firstVisiblePage + maxVisiblePages - 1, this.totalPages);

    if (lastVisiblePage - firstVisiblePage < maxVisiblePages - 1) {
      firstVisiblePage = Math.max(lastVisiblePage - maxVisiblePages + 1, 1);
    }

    this.visiblePages = [];
    for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
      this.visiblePages.push(i);
    }

    this.showEllipsisBeforeFirstPage = firstVisiblePage > 1;
    this.showEllipsisAfterLastPage = lastVisiblePage < this.totalPages;
  }
}
