import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { INameValue } from '@interfaces';

@Component({
  selector: 'app-custom-radio',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomRadioComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class CustomRadioComponent implements ControlValueAccessor {
  @Input() multiSelect: boolean = false;
  @Input() hasSelectAll: boolean = false;
  @Input() radio: string | string[] = null;
  @Input() radioList: INameValue[];
  @Input() prefix: string = '';
  @Input() useMark: boolean = false;
  @Input() showApplyBtn: boolean = false;

  @Output() closeEvent = new EventEmitter();

  onChange: Function;
  onTouched: Function;

  private inputValue: string;

  public allSelected: boolean = false;

  @HostListener('click', ['$event.target']) click(event) {
    this.toggle(event);
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.radio);
    }
  }

  get value() {
    return this.inputValue;
  }

  set value(value: string) {
    this.inputValue = value;
    if (this.multiSelect) {
      this.allSelected = value?.length === this.radioList?.length;
    }
    if (this.onChange) {
      this.onChange(value);
    }
  }

  toggle(event) {
    const eventId = event.id || event.parentElement?.id;
    if (this.multiSelect) {
      if (!this.radio) {
        this.radio = [];
      }
      if (this.radio instanceof Array) {
        this.radio = this.radio.includes(eventId)
          ? [...this.radio.filter((value) => value !== eventId)]
          : eventId?.length
            ? [...this.radio, eventId]
            : [...this.radio];
      }
      return;
    }

    this.radio = `${eventId}`.length ? eventId : this.radio;
  }

  selectAll(val: boolean) {
    this.radio = val ? this.radioList.map((r) => r.value) : [];
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  closeAction() {
    this.closeEvent.emit();
  }
}
