import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { ModalMarkersInClusterComponent, ModalWhenClickLabelOnLabelComponent, SliderModule } from '..';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '@directives';
import { LeafletModule } from '@bluehalo/ngx-leaflet';

@NgModule({
  declarations: [MapComponent, ModalWhenClickLabelOnLabelComponent, ModalMarkersInClusterComponent],
  imports: [CommonModule, SliderModule, TranslateModule, PipesModule, DirectivesModule, LeafletModule],
  exports: [MapComponent],
})
export class MapModule {}
